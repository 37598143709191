<template>
  <div id="recommend">
<!--
    <div class="icon">
      <img v-lazy="recommend.icon" />
    </div>
    <div class="content">
      非居民优惠办理
    </div>
    <div class="go">
      <div>点击申请</div>
      <img v-lazy="recommend.go" style="height: 16px" />
    </div>
    <div class="fill"></div>
-->
  </div>

</template>

<script>
  //import { swiper, swiperSlide } from 'vue-awesome-swiper';
  import 'swiper/dist/css/swiper.css';
  import { GoodsMixin } from '@/mixins/goodsMixin';
  import ajax from '@/api';

  export default {
    name: 'Recommend',
    mixins: [ GoodsMixin ],
    props: {
      recommend: { type: Object, default: () => ({ }) },
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 3,
          autoplay: true,
           pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
      }
    },
    methods: {
      /**
       * 加入购物车
       */
      async addToShopCart(goodsId) {
        if (!this.userToken) {
          this.$router.push({ name: 'Login' });
          return;
        }
        if (!goodsId) return;

        try {
          let res = await ajax.addToShopCart(goodsId);
          this.$toast(res.msg)
        } catch(error) {
          if (error.response && error.response.status === 401 || 400) this.$router.push({ name: 'Login' });
          console.log(error);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>
