<template>
  <div>
    refresh
  </div>
</template>

<script>
  export default {
    name: 'Refresh',
  }
</script>

<style lang="scss" scoped>

</style>