<template>
  <div class="cqhome_2">
    <div class="cqhome_notice flex-r flex-align-center">
      <img src="http://wx.cqzls.com/tpl/Wap/default/common/images/newcq/notice.png">
      <div class="notice_list">
        <div>2021年4月30日停水信息</div>
        <div>2021年5月01日停水信息</div>
      </div>
      <img src="http://wx.cqzls.com/tpl/Wap/default/common/images/new/icon30.png">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./style.scss";
@import url('//at.alicdn.com/t/font_2495309_td6tzikdqb.css');
</style>