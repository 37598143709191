<template>
  <div id="floor">
    <floor-title v-if="floorName" :num="num" :floorName="floorName"></floor-title>
    <!-- 楼层 -->
    <div class="floor-wrapper" v-if="type">
      <!-- 顶部 -->
<!--      <section class="floor-top">-->
<!--        <div class="floor-top-left" @click="goGoodsDetails(floor[0])">-->
<!--          <img v-lazy="floor[0].image"/>-->
<!--        </div>-->
<!--        <div class="floor-top-right">-->
<!--          <section class="border-btm" @click="goGoodsDetails(floor[1])"><img v-lazy="floor[1].image" /></section>-->
<!--          <section @click="goGoodsDetails(floor[2])"><img v-lazy="floor[2].image" /></section>-->
<!--        </div>-->
<!--      </section>-->
      <!-- 底部 -->
      <section class="floor-bottom">
        <div v-for="item of floor.slice(0,2)" :key="item.id"  style="    border-right: 0 !important;">
          <div class="floor-item" :class="{hide: item.id%2===1}">
            <img v-lazy="item.img" />
            <div style="display: inline-block; margin-left: 85px;    padding-top: 24px;" class="floor-content">


            <div style="font-weight: 600;">{{item.name}}</div>
              <div style="font-size: 10px">{{item.title}}</div>

            </div>

          </div>
        </div>
      </section>
      <section class="floor-bottom">
<!--        <div v-for="item of floor.slice(2)" :key="item.id" @click="goGoodsDetails(item)">-->
        <div v-for="item of floor.slice(2)" :key="item.id"   style="    border-right: 0 !important;">
          <div class="floor-item" :class="{hide: item.id%2===1}">
            <img v-lazy="item.img" />
            <div style="display: inline-block; margin-left: 85px;    padding-top: 24px;" class="floor-content">

            <div style="font-weight: 600;">{{item.name}}</div>
              <div style="font-size: 10px">{{item.title}}</div>
            </div>
          </div>
        </div>
      </section>
      <div class="fill"></div>

    </div>

    <div class="floor-wrapper floor-no-type" v-if="!type">
      <!-- 底部 -->
      <section class="floor-bottom" style="height: 120px">
        <div v-for="item of floor" :key="item.id"  style="text-align: center">
          <img v-lazy="item.img" style=" height: 110px;    padding-top: 5px;" />

        </div>
      </section>

      <div class="fill"></div>

    </div>


  </div>
</template>

<script>
  import FloorTitle from './FloorTitle';
  import { GoodsMixin } from '@/mixins/goodsMixin';

  export default {
    name: 'Floor',
    mixins: [ GoodsMixin ],
    components: { FloorTitle },
    props: [ 'floorName', 'floor', 'num', 'type'  ]
  }
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>