<template>
  <div id="title" v-show="floorName">
<!--    <span class="num" v-show="num">{{ num }}F</span>-->

    <span class="border">&nbsp;</span>
      <span class="name">{{ floorName }}</span>

  </div>
</template>

<script>
  export default {
    name: 'Title',
    props: [ 'floorName', 'num' ]
  }
</script>

<style lang="scss" scoped>
  $color:#b532e9;

  #title {
    /*background: #EDEDED;*/
    /*border-left: 2px solid black;*/

    height: 40px;
    line-height: 40px;
    text-align: left;
    color: $color;
    font-size: 15px;
    padding-left: 5px;
    position: relative;
    border-bottom: 1px solid #ddd;

    .border {
      height: 14px;
      width: 3px;
      background: black;
      display: inline-block;
      margin-top: 13px;

    }

    .name {
      height: 20px;
      line-height: 20px;
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 16px;
    }

    .num {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      background: $color;
      color: #fff;
      font-size: 12px;
      margin-right: 5px;
    }
  }
</style>