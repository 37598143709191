<template>
    <!-- 分类列表 -->
    <div id="category">
        <div id="swiper-wrapper">

                <div class="category-item" v-for="item of category" :key="item.id">
                    <div style="width: 100%; height: 100%;" v-show="item && item.img">
                        <img v-lazy="item.img" alt="" @click="clickCategory(item, index)"/>
                        <!--<p>{{ item.itemName }}</p>-->
                        <p>{{ item.name }}</p>
                    </div>
                </div>
                <div class="swiper-pagination" slot="pagination"></div>
        </div>

<!--        <ul v-show="category && category.length">-->
<!--            <li v-for="(item, index) in category.slice(1)" :key="item.mallCategoryId">-->
<!--                <img v-lazy="item.image" alt="" @click="clickCategory(item, index)"/>-->
<!--                <p>{{ item.mallCategoryName }}</p>-->
<!--            </li>-->
<!--            <li v-for="(item, index) in category.slice(1)" :key="item.mallCategoryId"-->
<!--                style="margin-top: 10px">-->
<!--                <img v-lazy="item.image" alt="" @click="clickCategory(item, index)"/>-->
<!--                <p>{{ item.mallCategoryName }}</p>-->
<!--            </li>-->
<!--        </ul>-->
        <!--    <section class="ad">-->
        <!--      <img v-lazy="advertesPicture" alt="" />-->
        <!--    </section>-->
    </div>
</template>

<script>
    import {swiper, swiperSlide} from 'vue-awesome-swiper';
    import 'swiper/dist/css/swiper.css';
    import { GoodsMixin } from '@/mixins/goodsMixin';
    import ajax from '@/api';

    export default {
        mixins: [GoodsMixin],
        name: 'Category',
        components: {swiper, swiperSlide},
        created() {
            // 获取户号
            ajax.getAccount({ openId: this.wxOpenId }).then(res => {
                this.guestNo = res.data;
            });
        },
        props: {
            category: {type: Array, default: () => []},
            advertesPicture: {type: String, default: ''}
        },
        data() {
          return {
              guestNo: [],
              index:"",
              item:"",
              swiperOption: {
                  slidesPerView: 4,
                  slidesPerColumn: 2,
                  // slidesPerColumnFill: "row",
                  slidesPerGroup: 8,

                  autoplay: false,
                      loop: false,
                      // loopFillGroupWithBlank: true,
                  pagination: {
                      el: '.swiper-pagination',
                      clickable: true
                  }
              },
              // swiperOption: {
              //     slidesPerView: 3,
              //     spaceBetween: 30,
              //     slidesPerGroup: 4,
              //     loop: true,
              //     loopFillGroupWithBlank: true,
              //     pagination: {
              //         el: '.swiper-pagination',
              //         clickable: true
              //     },
              // }
          }
        },
        methods: {
            clickCategory(item, index) {
                if (item.requireGuestNo && this.guestNo.length === 0) {
                    this.$dialog.alert({ message: '请先绑定户号' }).then(() => {
                        this.$router.push({ name: 'UserBind' });
                    })
                    return;
                }
                // 默认取大分类的第一个子分类 id
                //let categorySubId = item.bxMallSubDto[0].mallSubId;
                const urlMap = [
                    'UserBind',
                    'WaterPayment',
                    'PayRecord',
                    'WaterRecord'


                ]
                this.$router.push({
                    name: urlMap[item.id],
                    params: {index, item}
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './style.scss';
</style>
